/* eslint-disable react/prop-types */
import React, { useRef, forwardRef, useState, useEffect } from 'react';
import { useSpring, animated } from 'react-spring';
import { createPortal } from 'react-dom';
import { get } from 'lodash';

const ModalFromRight = forwardRef(
  ({ children, show, className, onClose }, ref) => {
    const { body } = document;
    const animatedRef = ref || useRef();
    const animatedProps = useSpring({
      config: { duration: 200 },
      right: show ? 0 : -525,
    });
    const [isReady, setIsReady] = useState(false);

    const checkIfDescendant = (e) => {
      const parent = get(animatedRef, 'current');
      const node = e.target;

      if (
        node.classList.contains('intercom-lightweight-app-launcher-icon') ||
        (document.querySelector('.intercom-lightweight-app-launcher-icon') &&
          document
            .querySelector('.intercom-lightweight-app-launcher-icon')
            .contains(node))
      ) {
        return;
      }

      if (!parent.contains(e.target)) {
        onClose();
        setIsReady(false);
      }
    };

    useEffect(() => {
      if (onClose) {
        if (isReady) {
          window.addEventListener('click', checkIfDescendant);
        } else {
          setIsReady(true);
        }
      }

      return () => {
        window.removeEventListener('click', checkIfDescendant);
      };
    }, [show, isReady]);

    useEffect(() => {
      if (show) document.body.classList.add('filter-modal-active');

      return () => {
        document.body.classList.remove('filter-modal-active');
      };
    }, [show]);

    if (!body) return null;

    return createPortal(
      <animated.div
        className={className}
        style={{
          right: animatedProps.right,
        }}
        ref={animatedRef}
        data-testid="modal-right-component"
      >
        {children}
      </animated.div>,
      body
    );
  }
);

ModalFromRight.defaultProps = {
  className: '',
  onClose: null,
};

export default ModalFromRight;
