/* eslint-disable no-return-assign */
import React, {
  useState,
  useEffect,
  useMemo,
  useRef,
  useCallback,
} from 'react';
import {
  CardHeader,
  Row,
  Col,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Input,
  Badge,
} from 'reactstrap';
import { useTranslation } from 'react-i18next';
import {
  debounce,
  has,
  get,
  isArray,
  omitBy,
  isNull,
  isUndefined,
  isObject,
  omit,
} from 'lodash';
import { useHistory, useLocation } from 'react-router-dom';
import uuidv4 from 'uuid/v4';
import styled from 'styled-components';
import qs from 'query-string';
import classNames from 'classnames';
import StarRating from '../StarRating';
import FilterAutocomplete from '../FilterAutocomplete';
import Icon from '../@v2/Icon';
import events from '../../helpers/events';
import ModalFromRight from '../ModalFromRight';
import { setTablePageSize } from '../../store/slices/settings.slice';
import Button from '../Button';
import {
  useSelector as useSelectorToolkit,
  dispatch as dispatchToolkit,
} from '../../store';
import useDidMountEffect from '../../helpers/custom-hooks';

const CardHeaderWrapper = styled(CardHeader)`
  position: relative;
  z-index: 10;
`;

const TableHeader = ({
  clearFilters,
  filterType,
  search,
  isLoading,
  children,
  filterInput,
  filterDesign,
  clearEventKey,
  onClearFilterCallback,
  customTableHeader,
  stateFilter,
  filterEventsKeys,
  clearId,
  searchText,
  defaultSort,
  customTableHeaderButton,
  defaultSearchValue,
  customDataSetter,
}) => {
  const animatedFilter = useRef();
  const history = useHistory();
  const location = useLocation();
  const { t } = useTranslation();
  const urlParams = useMemo(
    () => qs.parse(get(location, 'search')),
    [get(location, 'search')]
  );
  const [showFilter, setShowFilter] = useState(false);
  const getDefaultValueOfFilters = (passedfilters = null) => {
    return [...filterInput].reduce((accumulator, currentValue) => {
      if (
        passedfilters &&
        currentValue.changeByLocationState &&
        passedfilters[currentValue.filterKey]
      ) {
        if (isObject(currentValue.defaultValue)) {
          return {
            ...accumulator,
            [currentValue.filterKey]: currentValue.options.find(
              (item) => item.value === passedfilters[currentValue.filterKey]
            ),
          };
        }
      }

      return {
        ...accumulator,
        [currentValue.filterKey]: currentValue.defaultValue,
      };
    }, {});
  };

  const [filters, setFilters] = useState(getDefaultValueOfFilters());

  const filterLength = useMemo(() => {
    const f = { ...filters };

    Reflect.deleteProperty(f, 'pipelineStepId');

    const filterCount = Object.entries(f || {}).reduce(
      (accumulator, [, currentValue]) => {
        if (isObject(currentValue)) {
          if (
            get(currentValue, 'value') !== null &&
            get(currentValue, 'value') !== undefined &&
            get(currentValue, 'value') !== ''
          ) {
            return (accumulator += 1);
          }
        } else if (
          currentValue !== null &&
          currentValue !== undefined &&
          currentValue !== ''
        ) {
          return (accumulator += 1);
        }

        return accumulator;
      },
      0
    );

    if (location.pathname.includes('positions') || location.pathname === '/') {
      const filterStatus = get(f || filters, 'status.value');

      if (get(f, 'status') === null || (filterStatus && filterCount === 1)) {
        return filterCount;
      }
    }

    return filterCount;
  }, [filters]);

  const [sorts, setSorts] = useState(defaultSort || null);

  const debounceSearch = debounce((e) => {
    const state = { ...(location.state || {}) };
    let routeParams = { ...location };

    if (e.target.value === '') {
      const sFilters = get(state, 'filters') || {};
      routeParams = {
        ...location,
        state: {
          ...state,
          filters: omit(sFilters, search),
        },
        search: qs.stringify(urlParams),
        searchValue: e.target.value,
      };
    } else {
      routeParams = {
        ...location,
        state: {
          ...state,
          filters: {
            ...state.filters,
            [search]: e.target.value,
          },
        },
        search: qs.stringify({ ...urlParams, currentPage: 1 }),
        searchValue: e.target.value,
      };
    }

    history.push(routeParams);
  }, 500);

  const onSearch = (e) => {
    e.persist();
    debounceSearch(e);
  };

  const isDescendant = (parent, child) => {
    let node = child.parentNode;
    while (node !== null) {
      if (
        (node.classList &&
          (node.classList.contains('filter-modal') ||
            node.classList.contains('custom-react-select__menu-list'))) ||
        node === parent
      ) {
        return true;
      }

      node = node.parentNode;
    }

    return false;
  };

  const checkIfDescendant = (e) => {
    if (!isDescendant(animatedFilter.current, e.target)) {
      setShowFilter(false);
    }
  };

  useEffect(() => {
    if (showFilter && animatedFilter.current && filterDesign === 'modal') {
      window.addEventListener('click', checkIfDescendant);
    }

    return () => {
      window.removeEventListener('click', checkIfDescendant);
    };
  }, [showFilter, animatedFilter]);

  useEffect(() => {
    events.$on(clearEventKey, () => {
      const nFilter = [...filterInput]
        .filter((f) => !f.persist)
        .reduce(
          (accumulator, currentValue) => ({
            ...accumulator,
            [currentValue.filterKey]: currentValue.isMulti ? [] : null,
          }),
          {}
        );

      setFilters((state) => ({
        ...state,
        ...nFilter,
      }));
    });

    return () => {
      events.$off(clearEventKey);
    };
  }, []);

  const hideShowAllButton = useMemo(() => {
    const inputs = [...filterInput]
      .filter((f) => !f.persist)
      .filter((f) => {
        if (f.filterKeys && f.filterKeys.length) {
          for (let i = 0; i < f.filterKeys.length; i += 1) {
            if (stateFilter[f.filterKeys[i]]) {
              return true;
            }
          }
        }

        if (
          stateFilter &&
          f.filterKey &&
          stateFilter[f.filterKey] !== null &&
          stateFilter[f.filterKey] !== undefined
        ) {
          return true;
        }
        return false;
      });

    return !!inputs.length;
  }, [filterInput]);

  const applyFilter = async () => {
    let newFilters = {};
    const pureFilters = {};
    const withOnChangeLength = filterInput.filter(
      (item) => !!item.onChange
    ).length;
    const filtersValuesOnChange = filterInput.filter(
      (item) => !!item.valueOnFilter
    );
    let counter = 0;
    let isFinished = withOnChangeLength === 0;
    const sortedFilter = [...filterInput].sort((f) => (f.onChange ? 1 : -1));
    filterEventsKeys.forEach((key) => {
      if (filters[key]) {
        newFilters[key] = filters[key];
      }
    });

    const addHistoryState = () => {
      filtersValuesOnChange.forEach((item) => {
        Reflect.deleteProperty(newFilters, item.filterKey);
        if (filters[item.filterKey] && filters[item.filterKey].value) {
          newFilters = {
            ...newFilters,
            ...item.valueOnFilter[filters[item.filterKey].value],
          };
        }
      });

      history.push({
        ...location,
        state: {
          ...location.state,
          sorts,
          filters: omitBy(
            newFilters,
            (e) => isUndefined(e) || isNull(e) || e === ''
          ),
          pure: omitBy(
            pureFilters,
            (e) => isUndefined(e) || isNull(e) || e === ''
          ),
        },
        search: qs.stringify({
          ...urlParams,
          currentPage: 1,
          r: true,
        }),
      });
    };

    const callback = (f) => {
      newFilters = {
        ...newFilters,
        ...f,
      };

      counter += 1;

      if (counter === withOnChangeLength) {
        addHistoryState();
      }
    };

    sortedFilter.forEach((item, index) => {
      if (item.hide) {
        newFilters[item.filterKey] = get(
          location,
          `state.filters.${item.filterKey}`
        );
      } else {
        if (item.pure) {
          pureFilters[item.filterKey] = get(filters[item.filterKey], 'value');
          return;
        }
        if (item.filterKey) {
          const filterValue = get(filters[item.filterKey], 'value');

          if (
            isArray(filters[item.filterKey]) &&
            filters[item.filterKey].length
          ) {
            newFilters[item.filterKey] = filters[item.filterKey].reduce(
              (accumulator, currentValue) => {
                if (accumulator !== '') {
                  return `${accumulator}|${currentValue.label}`;
                }
                return currentValue.label;
              },
              ''
            );
          } else if (
            (filterValue !== undefined && filterValue !== null) ||
            (item.filterKey === 'stars' &&
              filters[item.filterKey] !== undefined &&
              filters[item.filterKey] !== null)
          ) {
            const checkedFilterValue =
              filterValue === false ? 'false' : filterValue;
            newFilters[item.filterKey] =
              item.filterKey === 'stars'
                ? filters[item.filterKey]
                : checkedFilterValue;
          } else if (filterValue === null) {
            Reflect.deleteProperty(newFilters, item.filterKey);
          }
        } else if (item.filterKeys) {
          item.filterKeys.forEach((key) => {
            if (filters[key] !== undefined && filters[key] !== null) {
              newFilters[key] = get(filters[key], 'value') || filters[key];
            }
          });
        }

        if (item.onChange) item.onChange(filters[item.filterKey], callback);
      }

      if (filterInput.length === index + 1) isFinished = true;
    });

    if (withOnChangeLength === 0 && isFinished) addHistoryState();
  };

  const debounceInput = useCallback(
    debounce((key, value) => {
      setFilters((state) => ({
        ...state,
        [key]: { value },
      }));
    }, 1000),
    []
  );

  const createFilters = () => (
    <Row
      className={classNames('align-items-center ie-flex', {
        'flex-column': filterDesign === 'modal',
      })}
    >
      {filterInput.map((item, index) => {
        if (item.hide) return null;
        if (item.stars) {
          return (
            <Col
              key={uuidv4()}
              style={{ height: 32, zIndex: filterInput.length - index }}
              className={classNames(
                'd-flex',
                filterDesign === 'default'
                  ? ' align-items-center mt-2 mb-2 mr-4 flex-grow-0 item-flex'
                  : 'mb-4 flex-column filter-modal'
              )}
            >
              <h5>{item.label}</h5>
              <StarRating
                clickable
                className={`filter--${index}`}
                rate={filters[item.filterKey]}
                onClick={(value) => {
                  if (filterDesign !== 'modal') {
                    if (filters[item.filterKey] === value) {
                      Reflect.deleteProperty(filters, item.filterKey);
                    } else {
                      filters[item.filterKey] = value;
                    }

                    history.push({
                      ...location,
                      state: {
                        ...location.state,
                        filters,
                      },
                      search: '',
                    });
                  }

                  if (filters[item.filterKey] === value) {
                    setFilters((state) => ({
                      ...state,
                      [item.filterKey]: null,
                    }));
                  } else {
                    setFilters((state) => ({
                      ...state,
                      [item.filterKey]: value,
                    }));
                  }
                }}
                data-testid={`filter-${index}-star`}
              />
            </Col>
          );
        }
        if (item.type === 'input') {
          return (
            <Col
              className={classNames(
                'd-flex',
                filterDesign === 'default'
                  ? ' align-items-center mt-2 mb-2 mr-4 flex-grow-0 item-flex'
                  : 'mb-4 flex-column filter-modal'
              )}
            >
              <h5>{item.label}</h5>
              <Input
                className={`filter--${index}`}
                style={{
                  zIndex: filterInput.length - index,
                }}
                name={filterInput.filterKey}
                autoComplete="off"
                defaultValue={filterInput.defaultValue}
                onChange={(e) => {
                  const { value } = e.target;
                  debounceInput(item.filterKey, value);
                }}
                data-testid={`filter-${index}-input`}
              />
            </Col>
          );
        }

        const filterValue = () => {
          if (item.filterKeys) {
            const options = item.options || item.defaultOptions;
            const arrValue = item.filterKeys.reduce(
              (accumulator, currentValue) => {
                if (
                  filters[currentValue] !== undefined &&
                  filters[currentValue] !== null
                ) {
                  return [
                    ...accumulator,
                    `${currentValue}|${filters[currentValue]}`,
                  ];
                }

                return accumulator;
              },
              []
            );
            const strValue = arrValue.join(',');

            return options.find((item) => item.value === strValue);
          }

          return filters[item.filterKey];
        };

        return (
          <FilterAutocomplete
            verticalAlign={filterDesign === 'modal'}
            key={uuidv4()}
            {...item}
            style={{
              zIndex: filterInput.length - index,
            }}
            className="filter-modal"
            selectClassName={`filter--${index}`}
            value={filterValue()}
            onChange={(response, triggeredAction) => {
              if (item.valueOnFilter) {
                const sorts = get(response, 'sorts');
                const newFilters = { ...filters };

                if (sorts !== undefined) setSorts(sorts);

                setFilters({
                  ...newFilters,
                  [item.filterKey]: response,
                });

                return;
              }

              setFilters((state) => {
                const f = { ...state };

                if (item.removeIfNoValue && !response) {
                  item.removeIfNoValue.forEach((rKey) => {
                    f[rKey] = null;
                  });
                }

                return {
                  ...f,
                  [item.filterKey]:
                    triggeredAction === 'clear' ? null : response,
                };
              });

              if (filterDesign === 'modal') return;

              const stateFilter = { ...(get(location, 'state.filters') || {}) };

              if (item.onChange) {
                item.onChange(triggeredAction === 'clear' ? {} : response);
              }
              if (
                (get(item, 'filterKey') || get(item, 'filterKeys')) &&
                !item.valueOnFilter
              ) {
                if (triggeredAction.action === 'remove-value') {
                  if (response && response.length) {
                    stateFilter[item.filterKey] = response
                      .map((item) => item.value)
                      .join('|');
                  } else {
                    Reflect.deleteProperty(stateFilter, item.filterKey);
                  }
                } else if (
                  triggeredAction.action === 'clear' ||
                  (response && !get(response, 'value') && response.length === 0)
                ) {
                  if (item.beforeStatePush) {
                    item.beforeStatePush({
                      filters: stateFilter,
                      clear: true,
                    });
                  }

                  Reflect.deleteProperty(stateFilter, item.filterKey);
                } else {
                  if (item.beforeStatePush) {
                    item.beforeStatePush({
                      response,
                      filters: stateFilter,
                    });
                  }

                  if (Array.isArray(response) && has(response[0], 'value')) {
                    stateFilter[item.filterKey] = response
                      .map((item) => item.value)
                      .join('|');
                  } else if (get(response, 'value') !== null) {
                    stateFilter[item.filterKey] = get(response, 'value');
                  } else {
                    Reflect.deleteProperty(stateFilter, item.filterKey);
                  }
                }

                history.push({
                  ...location,
                  state: {
                    ...location.state,
                    filters: stateFilter,
                  },
                  search: '',
                });

                return;
              }

              if (item.valueOnFilter) {
                const nFilter = { ...stateFilter };

                item.beforeStatePush({
                  filters: nFilter,
                });

                if (response && response.value) {
                  const splittedValue = response.value.split(',');
                  splittedValue.forEach((item) => {
                    const [key, value] = item.split('|');
                    let iValue = value;

                    if (value === 'true') iValue = true;
                    if (value === 'false') iValue = false;

                    nFilter[key] = iValue;
                  });
                }

                history.push({
                  ...location,
                  state: {
                    ...location.state,
                    filters: nFilter,
                  },
                  search: '',
                });
              }
            }}
            isDisabled={isLoading}
            resource={`filter-${index}`}
          />
        );
      })}
    </Row>
  );

  useEffect(() => {
    events.$on('set-table-filters', (params) => {
      const copiedFilter = { ...filters };

      if (params.filter) {
        setFilters({
          ...copiedFilter,
          [params.type]: params.filter,
        });
      } else {
        Reflect.deleteProperty(copiedFilter, params.type);
        setFilters(copiedFilter);
      }
    });

    return () => {
      events.$off('set-table-filters');
    };
  }, [filters, setFilters]);

  useDidMountEffect(() => {
    if (showFilter) applyFilter();
  }, [filters]);

  useDidMountEffect(() => {
    if (showFilter) return;
    setFilters(getDefaultValueOfFilters(location?.state?.filters));
  }, [get(location, 'state')]);

  useEffect(() => {
    if (showFilter) document.body.classList.add('filter-modal-active');

    return () => {
      document.body.classList.remove('filter-modal-active');
    };
  }, [showFilter]);

  const pageSize = useSelectorToolkit(
    ({ settings }) => get(settings, 'table.pageSize') || 10
  );

  const [pageSizeOptionsOpen, setPageSizeOptionsOpen] = useState(false);

  return (
    <CardHeaderWrapper className="d-block ts-custom-table-header">
      {customTableHeader}
      {!customTableHeader && (
        <div className="card-header-wrapper d-flex align-items-center justify-content-between">
          {!!search && (
            <div className="card-header-input d-flex align-items-center">
              {isLoading ? (
                <img
                  className="search-input-loader"
                  src={`${global?.appConfig?.public_url}/images/loader.svg`}
                  alt="loader"
                />
              ) : (
                <Icon name="search" />
              )}
              <input
                data-testid="table-search-input"
                placeholder={searchText || t('general.search')}
                defaultValue={
                  defaultSearchValue ||
                  get(location, `state.filters.${[search]}`) ||
                  ''
                }
                onChange={onSearch}
                className="data-search"
              />
            </div>
          )}
          {filterType === 'server-side' && (
            <Dropdown
              style={{ marginRight: '0.500rem' }}
              isOpen={pageSizeOptionsOpen}
              toggle={() => setPageSizeOptionsOpen(!pageSizeOptionsOpen)}
              className="dropdown--per-page"
              data-testid="table-per-page-dropdown"
            >
              <DropdownToggle className="btn-naked button--per-page" caret>
                {t('general.items-per-page', { x: pageSize })}
              </DropdownToggle>
              <DropdownMenu right>
                {[10, 20, 50, 100].map((item, index) => (
                  <DropdownItem
                    key={item}
                    className={classNames(
                      'm-0',
                      `dropdown--per-page-option-${index}`
                    )}
                    style={{
                      height: 'auto',
                      lineHeight: 'auto',
                    }}
                    onClick={() => {
                      if (customDataSetter) {
                        customDataSetter(item);
                      }
                      history.push({
                        ...location,
                        search: qs.stringify({
                          ...urlParams,
                          currentPage: 1,
                          pageSize: item,
                        }),
                      });
                      dispatchToolkit(setTablePageSize(item));
                    }}
                  >
                    {item}
                  </DropdownItem>
                ))}
              </DropdownMenu>
            </Dropdown>
          )}
          {!!filterInput.length && (
            <div className="buttons d-flex align-items-center">
              {hideShowAllButton && (
                <Button
                  className="d-flex align-items-center btn-light-gray button--show-all"
                  onClick={() => {
                    if (onClearFilterCallback) onClearFilterCallback();

                    events.$emit(`clear-${clearId}`);
                    clearFilters();
                  }}
                  disabled={isLoading}
                  data-testid="table-show-all-button"
                >
                  {t('general.show-all')}
                </Button>
              )}
              <Button
                outline
                className="d-flex align-items-center btn-light-gray position-relative button--table-filter"
                onClick={() => {
                  setTimeout(() => {
                    setShowFilter((state) => !state);
                  });
                }}
                disabled={isLoading}
                data-testid="table-filter-button"
              >
                <Icon className="btn-light-gray" name="sliders-h" />
                {t('general.filter')}
                {!!filterLength && (
                  <Badge
                    className="position-absolute"
                    style={{
                      top: '-6px',
                      right: '-8px',
                    }}
                    color="primary"
                    pill
                  >
                    {filterLength}
                  </Badge>
                )}
              </Button>
            </div>
          )}
          {customTableHeaderButton}
        </div>
      )}
      {showFilter && filterDesign === 'default' && !!filterInput.length && (
        <div className="card-header--filter">
          {createFilters()}
          {children}
        </div>
      )}
      {filterDesign === 'modal' && !!filterInput.length && (
        <ModalFromRight
          ref={animatedFilter}
          show={showFilter}
          className="card-header--filter-modal"
        >
          <div className="card-header--filter-modal-container pt-5">
            <div
              className="d-flex align-items-center justify-content-end clickable"
              data-testid="filter-close-button"
              onClick={() => {
                setShowFilter(false);
              }}
            >
              <Icon name="times" />
            </div>
            <div style={{ position: 'relative', zIndex: 2 }}>
              {createFilters()}
            </div>
            <div style={{ position: 'relative', zIndex: 1 }}>{children}</div>
          </div>
        </ModalFromRight>
      )}
    </CardHeaderWrapper>
  );
};

TableHeader.defaultProps = {
  defaultSearchValue: '',
};

export default TableHeader;
