/* eslint-disable react/no-unused-prop-types */
import React, { useState, useEffect, useMemo } from 'react';
import { FormGroup, Input, Button, Label } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { NotificationManager } from 'react-notifications';
import { get } from 'lodash';
import CustomButton from '../Button';
import WysiwygEditor from '../wysiwyg/WysiwygEditor';
import Timeline from './Timeline';
import '../../assets/sass/candidates/email.sass';
import { useAPI } from '../../context/api';
import { renderErrorField } from '../../helpers/form';
import { log } from '../../helpers/utils';
import { selectUser } from '../../store/selectors/user.selector';
import { selectPosition } from '../../store/selectors/settings.selector';
import { useSelector, useSelector as useSelectorToolkit } from '../../store';
import { selectClient } from '../../store/selectors/client.selector';
import { getAllEmailTemplates } from '../../store/api/template.api';
import { getPosition } from '../../store/api/positions.api';
import { getUserByUserId } from '../../store/api/user.api';
import Select from '../@v2/Select/Select';

const NO_REPLY_SENDER = null;

const Email = ({ activeCandidate, candidatesSettings, filters, callback, entries, loading }) => {
  const { selectAll, unchecked, selected: candidates } = candidatesSettings;
  const activePosition = useSelector(selectPosition);

  const selected = useMemo(
    () =>
      candidates.map((item) => {
        if (item && item.id) {
          return item.id;
        }

        return item;
      }),
    [candidates]
  );
  const [bulkAPI] = useAPI('bulk');
  const currentUser = useSelectorToolkit(selectUser);
  const { email, firstName, lastName, userId } = currentUser;
  const { position } = useSelectorToolkit(selectPosition);
  const activeClient = useSelectorToolkit(selectClient);
  const [editorState, setEditorState] = useState('');
  const [subject, setSubject] = useState('');
  const [sender, setSender] = useState('');
  const [isPreviewActive, setIsPreviewActive] = useState(false);
  const [isSending, setIsSending] = useState(false);
  const [editorTemplates, setEditorTemplates] = useState(null);
  const [touchedFields, setTouchedFields] = useState({});
  const [defaultTeamMembersOptions, setDefaultTeamMembersOptions] = useState([]);
  const { t } = useTranslation();
  const getDefaultTeamMembers = async () => {
    try {
      const positionData = await getPosition(activePosition.positionId || activeCandidate.positionId);
      let recruiter = null;
      if (activePosition.recruiterId) {
        recruiter = await getUserByUserId(activePosition.recruiterId);
      }

      if (positionData && positionData.teamMembers) {
        const members = positionData.teamMembers;
        let membersArr = [
          ...members.map((item) => ({
            ...item,
            value: item.id,
            label: `${item.firstName} ${item.lastName}`,
          })),
        ];

        if (recruiter && recruiter?.userId !== userId) {
          membersArr = [
            ...membersArr,
            {
              value: recruiter.userId,
              label: `${recruiter.firstName} ${recruiter.lastName}`,
              id: recruiter.userId,
              ...recruiter,
            },
          ];
        }

        if (!members.find((item) => item.id === userId)) {
          membersArr.unshift({
            value: userId,
            label: `${firstName} ${lastName}`,
            email,
            id: userId,
            userId,
          });
        }

        setDefaultTeamMembersOptions([
          ...membersArr,
          {
            value: NO_REPLY_SENDER,
            label: `${get(activeClient, 'name')} (no reply)`,
            email: global?.appConfig?.no_reply_sender,
            userId: null,
          },
        ]);
      }
    } catch (error) {
      log(error);
    }
  };

  const onTemplateChange = (item) => {
    if (item) {
      setSubject(item.subject || '');
      setEditorState(item.text || '');
    }
  };

  useEffect(() => {
    if (defaultTeamMembersOptions?.length) {
      const userRecruiter = defaultTeamMembersOptions?.find((teamMember) => {
        return teamMember?.email === email;
      });

      if (userRecruiter) {
        setSender(userRecruiter);
      } else {
        setSender(defaultTeamMembersOptions[0]);
      }
    }
  }, [defaultTeamMembersOptions]);

  const getAllTemplates = async () => {
    try {
      const { items } = await getAllEmailTemplates();
      if (items && Array.isArray(items)) {
        setEditorTemplates(
          items.map((item) => ({
            value: item.id,
            label: item.name,
            item,
          }))
        );
      }
    } catch (e) {
      log(e);
    }
  };

  useEffect(() => {
    getAllTemplates();
    getDefaultTeamMembers();
  }, []);

  const onSubmit = async (e) => {
    e.preventDefault();
    setTouchedFields((state) => ({ ...state, subject: true }));

    if (isSending || subject === '') {
      return;
    }

    try {
      setIsSending(true);
      const payload = {
        selectAll,
        userId: sender.userId || sender.id,
        subject,
        text: editorState,
      };

      if (selectAll) {
        let payloadFilters = [];

        Object.keys(filters).forEach((key) => {
          if (filters[key]) {
            payloadFilters = [...payloadFilters, `${key}==${filters[key]}`];
          }
        });

        payload.filters = payloadFilters.join(',');

        if (unchecked.length) {
          payload.unchecked = unchecked.map((item) => item.id);
        }
      } else {
        payload.candidates = selected;
      }

      const response = await bulkAPI.email(payload);
      if (response) {
        setTimeout(() => {
          NotificationManager.success(
            selected.length === 1 ? t('email.sent-successfully') : t('email.sent-successfully-multiple-candidates')
          );
          setSubject('');
          setEditorState('');
          setTouchedFields({});

          if (selected.length === 1) callback();
          setIsSending(false);
          // Adding a delay to allow the user to see the success message
          // TODO: Add a webhooks to update the timeline
        }, 4000);
      }
    } catch (error) {
      log('error', error);
    }
  };

  const getDataFromObject = (object, key) => get(object, key, '');
  const getPreview = (object) =>
    object
      .replace(/{candidate.firstname}/g, getDataFromObject(activeCandidate, 'firstName'))
      .replace(/{candidate.lastname}/g, getDataFromObject(activeCandidate, 'lastName'))
      .replace(/{candidate.phonenumber}/g, getDataFromObject(activeCandidate, 'phoneNumber'))
      .replace(/{position.name}/g, getDataFromObject(activeCandidate, 'positionName') || position)
      .replace(/{location.name}/g, getDataFromObject(activeCandidate, 'locationName'));

  return (
    <div className="email-area">
      <form className="email-form" onSubmit={onSubmit}>
        {!isPreviewActive && (
          <>
            <FormGroup>
              <Label for="sender">{t('general.send-as')}</Label>
              <Select
                searchable
                searchKey="label"
                resource="candidates-subject-sender"
                value={[sender]}
                onSelect={([selected]) => {
                  setSender(selected);
                }}
                defaultOptions={defaultTeamMembersOptions}
                id="sender"
              />
            </FormGroup>
            <FormGroup>
              <Label for="sender">{t('general.subject')}</Label>
              <Input
                data-testid="candidates-subject-input"
                id="subject"
                value={subject}
                placeholder={t('general.subject')}
                onChange={({ target }) => {
                  setSubject(target.value);
                }}
                invalid={touchedFields.subject && subject === ''}
              />
              {!!touchedFields.subject && renderErrorField(subject === '')}
            </FormGroup>
            <Label for="sender">{t('general.email')}</Label>
            <WysiwygEditor
              key={subject}
              id="text-body"
              toolbarId="toolbar-email-editor"
              toolbar="candidate-email-toolbar"
              onTemplateChange={onTemplateChange}
              placeholder={t('candidates.email.write-email')}
              options={{ listItems: editorTemplates }}
              value={editorState || ''}
              onEditorStateChange={setEditorState}
              resource="candidates-body"
            />
          </>
        )}
        {isPreviewActive && (
          <>
            <p className="email-subject" dangerouslySetInnerHTML={{ __html: getPreview(subject) }} />
            {!!editorState && (
              <div className="email-content" dangerouslySetInnerHTML={{ __html: getPreview(editorState) }} />
            )}
            <div
              className="email-signature"
              dangerouslySetInnerHTML={{
                __html: sender?.signatureHtml,
              }}
            />
          </>
        )}
        <div className="d-flex justify-content-end button-container">
          <Button
            data-testid="candidates-preview-button"
            id="button--preview"
            color="outline-primary"
            type="button"
            onClick={() => {
              setIsPreviewActive((currentState) => !currentState);
            }}
          >
            {isPreviewActive ? t('candidates.email.edit-content') : t('candidates.email.preview')}
          </Button>
          <CustomButton
            data-testid="candidates-send-button"
            id="button--send"
            color="success"
            loading={isSending}
            type="submit"
          >
            {t('candidates.email.send-mail')}
          </CustomButton>
        </div>
      </form>
      {candidates.length === 1 && candidates[0] && (
        <Timeline
          activeCandidate={activeCandidate}
          entries={entries.filter((item) => [5, 6, 19].includes(item.type))}
          loading={loading}
        />
      )}
    </div>
  );
};

export default Email;
