import React, { useState, useMemo, useEffect, useRef } from 'react';
import { ListGroupItem, Collapse, Card, CardBody, Col, Label, FormGroup, Input } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { get, isArray } from 'lodash';
import Switch from '../../input/Switch';
import Icon from '../../@v2/Icon';
import Select from '../../input/Select';
import SelectV2 from '../../@v2/Select/Select';
import WysiwygEditor from '../../wysiwyg/WysiwygEditor';
import ScreeningQuestionsType from '../../../constants/ScreeningQuestionsType';
import { useSelector as useSelectorToolkit } from '../../../store';
import { selectOptions } from '../../../store/selectors/settings.selector';

const CustomBadge = ({ color, label, textColor }) => (
  <div className="custom-badge" style={{ backgroundColor: color }}>
    <span style={{ color: textColor || 'var(--text-color)' }}>{label || ''} </span>
  </div>
);

CustomBadge.defaultProps = {
  textColor: 'var(--text-color)',
};

const Category = ({
  type,
  color,
  backgroundColor,
  stateKey,
  state,
  setState,
  questions,
  parsedQuestions,
  editorTemplates,
}) => {
  const { t } = useTranslation();
  const toolbarId = useMemo(() => `toolbar-${type}`, []);
  const settings = useMemo(() => (state[stateKey] ? JSON.parse(state[stateKey]) : {}), [stateKey, state]);

  const [notifiedUsers, setNotifiedUsers] = useState([]);
  const isNotifiedUsersSet = useRef(false);
  const [isOpen, setIsOpen] = useState(false);
  const [emailText, setEmailText] = useState(settings.candidateEmailText || '');
  const options = useSelectorToolkit(selectOptions);
  const recruiters = options?.recruiters;

  const getExistingUsers = async (existingUserIds) => {
    try {
      setNotifiedUsers(
        recruiters
          .filter((recruiter) => existingUserIds.includes(recruiter.id))
          .map((user) => ({
            ...user,
            value: user.id,
            label: user.name,
          }))
      );
      isNotifiedUsersSet.current = true;
    } catch (error) {
      // do nothing
    }
  };

  useEffect(() => {
    const notifiedUsers = get(settings, 'notifiedUserIdJsonString');
    let parsedNotifiedUsers = [];
    if (isArray(notifiedUsers)) {
      parsedNotifiedUsers = notifiedUsers;
    } else if (notifiedUsers) {
      parsedNotifiedUsers = JSON.parse(notifiedUsers);
    }
    if (parsedNotifiedUsers.length >= 1 && !isNotifiedUsersSet.current) {
      getExistingUsers(parsedNotifiedUsers);
    }
  }, [settings, isNotifiedUsersSet]);

  const onTemplateChange = (item) => {
    if (item) {
      setState(
        stateKey,
        JSON.stringify({
          ...settings,
          candidateEmailSubject: item?.subject,
          candidateEmailText: item?.text || emailText,
        })
      );
    }
  };

  useEffect(() => {
    setState(
      stateKey,
      JSON.stringify({
        ...settings,
        candidateEmailText: emailText,
      })
    );
  }, [emailText]);

  const defaultAnswer = {
    value: null,
    label: t('edit-position.does-not-affect-categorization'),
  };

  const emailDelayOptions = [
    {
      value: 0,
      label: t('general.send-directly'),
    },
    {
      value: 4,
      label: t('general.send-four-hours-after-application'),
    },
    {
      value: 24,
      label: t('general.send-24-hours-after-application'),
    },
  ];

  const getOptions = (question) => {
    if (question.type === ScreeningQuestionsType.CHECKBOX_TYPE) {
      return [
        { label: t('general.yes-must-be-selected'), name: '1', value: 1 },
        { label: t('general.no-must-be-selected'), name: '0', value: 0 },
      ];
    }

    if (question.type === ScreeningQuestionsType.YEARS_TYPE || question.type === ScreeningQuestionsType.SLIDER_TYPE) {
      const key = question.sliderMax - question.sliderMin;
      let arr = [question.sliderMin];

      if (key >= 0) {
        arr = [
          ...arr,
          ...Array.from(Array(question.sliderMax - question.sliderMin).keys()).map(
            (item) => question.sliderMin + item + 1
          ),
        ];
      }

      return arr
        .filter((item) => item !== 0)
        .map((obj) => ({
          label: t(
            question.type === ScreeningQuestionsType.YEARS_TYPE ? 'general.atleast-x-years' : 'general.atleast-x',
            { x: obj }
          ),
          name: obj,
          value: obj,
        }));
    }

    if (question.type === ScreeningQuestionsType.SELECTIONS_TYPE) {
      return [
        ...question.selections.map((item) => {
          item.label = `${item.name} ${t('general.must-be-chosen')}`;
          return item;
        }),
      ];
    }

    return [];
  };

  return (
    <div className="position-form-holder" data-testid={`position-category-${type}`}>
      <ListGroupItem>
        <div className="custom-badge-wrapper">
          <CustomBadge color={backgroundColor} textColor={color} label={type !== 'Other' ? type : ''} />
          <span>{type !== 'Other' ? t('general.candidates') : t('general.other')} </span>
        </div>
        <Switch
          checked={settings.active}
          label={
            settings.active ? t('position.category.currently-active') : t('position.category.currently-not-active')
          }
          onChange={(state) => {
            setState(
              stateKey,
              JSON.stringify({
                ...settings,
                active: state,
              })
            );
          }}
          id={`currently-active-${type}`}
          data-testid={`position-category-${type}-active-switch`}
          disabled={false}
        />
        <div
          data-testid={`category-handler-${type}`}
          className="icon-wrapper"
          onClick={() => {
            setIsOpen((currentState) => !currentState);
          }}
        >
          <Icon name="sort-down" size="1x" type="fas" />
        </div>
      </ListGroupItem>
      <Collapse isOpen={isOpen}>
        <Card>
          <CardBody>
            <Col md="12" sm="12" xs="12">
              {type !== 'Other' &&
                questions.map((question, index) => (
                  <div key={question.screeningCriteriaId} className="category-questions-list">
                    <Label for={`${question.screeningCriteriaId}`}>
                      {index + 1}. {question.label}
                    </Label>
                    {question.type &&
                      question.type !== ScreeningQuestionsType.TEXT_TYPE &&
                      question.type !== ScreeningQuestionsType.MULTI_SELECT && (
                        <Select
                          resource={`position-category-${type}-${index}`}
                          id={`categorizing-select-${type}-${index}`}
                          name={`categorizing-select-${type}-${index}`}
                          value={
                            question[`requirement${type}`] !== null
                              ? getOptions(question).find(
                                  (item) =>
                                    item.screeningCriteriaSelectionId === question[`requirement${type}`] ||
                                    item.value === question[`requirement${type}`]
                                )
                              : defaultAnswer
                          }
                          options={[defaultAnswer, ...getOptions(question)]}
                          onChange={(selected) => {
                            const cloneQuestions = [...parsedQuestions];

                            cloneQuestions.map((item) => {
                              if (item.screeningCriteriaId === question.screeningCriteriaId) {
                                item[`requirement${type}`] = selected.value;
                              }
                              return item;
                            });

                            setState('questionsJsonString', JSON.stringify(cloneQuestions));
                          }}
                        />
                      )}
                  </div>
                ))}
              <FormGroup>
                <Label for="send-email-to-the-candidate">{t('edit-position.send-email-to-the-candidate')}</Label>
                <Switch
                  id={`categorizing-send-email-to-the-candidates-${type}`}
                  name={`categorizing-send-email-to-the-candidates-${type}`}
                  data-testid={`position-category-${type}-send-email-switch`}
                  checked={settings.sendMailToCandidate}
                  onChange={(state) => {
                    setState(
                      stateKey,
                      JSON.stringify({
                        ...settings,
                        sendMailToCandidate: state,
                      })
                    );
                  }}
                />
              </FormGroup>
              <FormGroup>
                <Label for="reject-candidate-automatically">{t('edit-position.reject-candidate-automatically')}</Label>
                <Switch
                  id={`categorizing-reject-candidate-automatically-${type}`}
                  name={`categorizing-reject-candidate-automatically-${type}`}
                  data-testid={`position-category-${type}-reject-candidate-switch`}
                  checked={settings.autoRejectCandidate}
                  onChange={(state) => {
                    setState(
                      stateKey,
                      JSON.stringify({
                        ...settings,
                        autoRejectCandidate: state,
                      })
                    );
                  }}
                />
              </FormGroup>
              <FormGroup>
                <Label for="subject">{t('general.subject')}</Label>
                <Input
                  id={`categorizing-subject-${type}`}
                  name={`categorizing-subject-${type}`}
                  data-testid={`position-category-${type}-subject-input`}
                  value={settings.candidateEmailSubject}
                  onChange={(e) => {
                    setState(
                      stateKey,
                      JSON.stringify({
                        ...settings,
                        candidateEmailSubject: e.target.value,
                      })
                    );
                  }}
                />
              </FormGroup>
              <FormGroup>
                <Label for="message">{t('general.message')}</Label>
                <WysiwygEditor
                  key={`position-category-${type}-message`}
                  resource={`position-category-${type}-message`}
                  id={`wysiwyg-${type}`}
                  data-testid={`criteria-message-wysiwyg-${type}`}
                  toolbarId={toolbarId}
                  toolbar="candidate-email-toolbar"
                  placeholder={t('candidates.email.write-email')}
                  options={{ listItems: editorTemplates }}
                  defaultValue={emailText}
                  onEditorStateChange={setEmailText}
                  onTemplateChange={onTemplateChange}
                />
              </FormGroup>
              <FormGroup>
                <Label for="email-delay">{t('general.email-delay')}</Label>
                <Select
                  resource={`position-category-${type}-email-delay`}
                  id={`categorizing-email-delay-${type}`}
                  name={`categorizing-email-delay-${type}`}
                  value={emailDelayOptions.find((item) => item.value === settings.candidateEmailDelayHours)}
                  onChange={(selected) => {
                    setState(
                      stateKey,
                      JSON.stringify({
                        ...settings,
                        candidateEmailDelayHours: selected.value,
                      })
                    );
                  }}
                  options={emailDelayOptions}
                />
              </FormGroup>
              <FormGroup>
                <Label for="informed-users">{t('general.informed-users')}</Label>
                <SelectV2
                  resource={`position-category-${type}-informed-users`}
                  id={`categorizing-informed-users-${type}`}
                  name={`categorizing-informed-users-${type}`}
                  multiple
                  clearable
                  searchable={false}
                  defaultOptions={recruiters}
                  onSelect={(selected) => {
                    setNotifiedUsers(selected);
                    setState(
                      stateKey,
                      JSON.stringify({
                        ...settings,
                        notifiedUserIdJsonString: JSON.stringify(selected.map((item) => item.value || item.id)),
                        notifiedUsers: selected.map((item) => ({
                          id: item.value || item.id,
                          name: item.name || item.label,
                        })),
                      })
                    );
                  }}
                  value={notifiedUsers}
                  keys={['id', 'name']}
                />
              </FormGroup>
            </Col>
          </CardBody>
        </Card>
      </Collapse>
    </div>
  );
};

export default Category;
