import React, { useEffect, useMemo, useState } from 'react';
import { createPortal } from 'react-dom';
import { useTranslation } from 'react-i18next';
import { get, sortBy, omit } from 'lodash';
import { useApiRequestContext } from '../../../context/@v2/ApiRequestContext';
import useElementClickHandlers from '../../../hooks/useElementClickHandlers';
import Filter from '../../../components/@v2/Filter/Filter';
import SelectFilter from '../../../components/@v2/Filter/SelectFilter';
import ButtonFilter from '../../../components/@v2/Button/ButtonFilter';
import useFilterModal from '../../../hooks/useFilterModal';
import Icon from '../../../components/@v2/Icon';
import { useSelector as useSelectorToolkit, dispatch as dispatchToolkit } from '../../../store';
import { setActivePosition, setFiltersWithKey } from '../../../store/slices/settings.slice';
import {
  candidatesOptions,
  categoriesOptions as categories,
  documentsOptions as documents,
} from '../../../constants/Candidates';
import StarRating from '../../../components/StarRating';
import { browseDepartments } from '../../../store/api/departments.api';
import { getTagsLookup } from '../../../store/api/candidates.api';
import { createQuestions } from '../../../constants/ScreeningQuestions';
import ScreeningQuestionsType from '../../../constants/ScreeningQuestionsType';
import { getPipelineSteps, getPositionLookupList, getScreeningCriterias } from '../../../store/api/positions.api';
import { log } from '../../../helpers/utils';

const CandidatesFilter = () => {
  const { t } = useTranslation();
  const [isFilterShown, setIsFilterShown] = useState(false);
  const { meta, setMeta } = useApiRequestContext();
  const options = useSelectorToolkit(({ settings }) => settings.options);
  const position = useSelectorToolkit(({ settings }) => settings.position);
  const departmentsOptions = options?.departments || [];
  const candidatesFilter = useSelectorToolkit(({ settings }) => settings?.filters?.candidates);

  const tags = candidatesFilter?.tagNames || [];

  const StatusTypes = ['Rejected'];

  const [selectedTags, setSelectedTags] = useState(
    tags?.length
      ? tags?.map((item) => {
          return {
            label: item,
            value: item,
            name: item,
          };
        })
      : []
  );

  const statusOptions = candidatesOptions(t);
  const documentsOptions = documents(t);
  const categoryOptions = categories(t);
  const [screeningQuestions, setScreeningQuestions] = useState([]);

  const getScreeningQuestionsList = async (id) => {
    try {
      const response = await getScreeningCriterias(id);
      setScreeningQuestions(sortBy(response, 'sortOrder'));
    } catch (error) {
      log(error);
    }
  };

  useEffect(() => {
    if (candidatesFilter?.positionId) {
      getScreeningQuestionsList(candidatesFilter?.positionId);
    }
  }, [candidatesFilter?.positionId]);

  useElementClickHandlers({
    condition: isFilterShown,
    callback: setIsFilterShown,
    elements: ['.ts-filter-button', '.ts-filter', '.rc-dropdown', '.searchable-select'],
  });

  useFilterModal({
    show: isFilterShown,
  });

  const changeMeta = (key, value, label, isStatus = null) => {
    let currentFilters = [...meta.filters].filter((item) => item.key !== key);
    if (isStatus) {
      currentFilters = currentFilters.filter((item) => !StatusTypes.includes(item.key));
    }

    setMeta({
      ...meta,
      filters:
        value === '' || isStatus || !!value
          ? [...currentFilters, { key, value, label: label || null }]
          : currentFilters,
      page: 1,
    });
    setIsFilterShown(false);
  };

  const changeMetaFilters = (filters) => {
    setMeta({
      ...meta,
      filters,
      page: 1,
    });

    setIsFilterShown(false);
  };

  const getFirstPipelineStep = (pipelines) => {
    if (pipelines && pipelines.length) {
      const findItemWithCandidates = sortBy(pipelines, 'sortOrder').find((item) => item.candidates >= 1);
      if (findItemWithCandidates) {
        return findItemWithCandidates;
      }
    }

    return null;
  };

  const getFilterValue = (key, defaultValue = null, callback) => {
    const foundItem = meta?.filters?.find((item) => item?.key === key);
    if (callback) {
      const callbackValue = callback(foundItem);

      if (callbackValue?.value) {
        return callbackValue;
      }
    }

    if (foundItem?.value) {
      return foundItem;
    }

    return defaultValue;
  };

  const departmentValue = getFilterValue('departmentId');

  const onDepartmentSelect = (selected) => {
    changeMeta(
      'departmentId',
      selected?.value || selected?.departmentId || null,
      selected?.label || selected?.name || null
    );
  };

  const onPositionSelect = async (selected) => {
    const selectedValue = selected?.value || selected?.id;

    const defaultFilters = [
      ...meta?.filters?.filter((item) => item?.key !== 'positionId' && item?.key !== 'pipelineStepId'),
      {
        key: 'positionId',
        value: selectedValue,
      },
    ];

    if (selectedValue) {
      const pipelines = await getPipelineSteps(selectedValue, {
        filters: 'status==Active',
      });

      dispatchToolkit(
        setActivePosition({
          ...selected,
          positionId: selectedValue,
          pipelines,
          activePipelineStepId: get(getFirstPipelineStep(pipelines), 'id') || null,
        })
      );

      dispatchToolkit(
        setFiltersWithKey({
          key: 'candidates',
          payload: {
            positionId: selectedValue,
            pipelineStepId: get(getFirstPipelineStep(pipelines), 'id') || null,
          },
        })
      );

      if (get(getFirstPipelineStep(pipelines), 'id')) {
        changeMetaFilters([
          ...defaultFilters,
          {
            key: 'pipelineStepId',
            value: get(getFirstPipelineStep(pipelines), 'id'),
          },
        ]);
      } else {
        changeMetaFilters(defaultFilters);
      }

      return;
    }

    dispatchToolkit(setActivePosition({}));
    dispatchToolkit(
      setFiltersWithKey({
        key: 'candidates',
        payload: {
          positionId: null,
          pipelineStepId: null,
        },
      })
    );

    changeMetaFilters(defaultFilters);
  };

  const onTagsSelect = async (selected) => {
    setSelectedTags(selected);
    if (selected?.length) {
      changeMeta(
        'tagNames',
        selected?.map((item) => item.value || item.name)
      );
      return;
    }

    changeMeta('tagNames', null);
  };

  const onRateSelect = (rate) => {
    const currentRate = meta?.filters?.find((item) => item.key === 'rating');

    if (currentRate?.value === rate) {
      changeMeta('rating', null);
      return;
    }

    changeMeta('rating', rate);
  };

  const onStatusSelect = async (selected) => {
    const theFilter = candidatesFilter;
    const payloadFilter = omit(theFilter, ['rejected']);
    if (selected?.value) {
      payloadFilter.rejected = selected?.value === 'Rejected';
    }
    dispatchToolkit(
      setFiltersWithKey({
        key: 'candidates',
        payload: { ...payloadFilter, status: selected?.value },
      })
    );
    changeMeta('status', selected?.value);
    if (selected?.value) {
      changeMeta('rejected', selected?.value === 'Rejected', null, true);
    } else {
      changeMeta('rejected', null);
    }
  };

  const screeningQuestionsValues = useMemo(() => {
    const currentScreeningQuestions = candidatesFilter?.hasScreeningQuestions?.split('|') || [];

    const screeningQuestionsShape = {};

    currentScreeningQuestions?.forEach((screeningQuestion) => {
      const [id, , value] = screeningQuestion?.split('~');
      if (value) {
        screeningQuestionsShape[id] = value;
      }
    });
    return screeningQuestionsShape;
  }, [candidatesFilter?.hasScreeningQuestions]);

  const getScreeningQuestionValue = (screeningQuestion, options) => {
    const screeningQuestionValue = screeningQuestionsValues?.[screeningQuestion?.id];

    if (screeningQuestionValue) {
      if (screeningQuestionValue?.includes(',')) {
        return options?.filter((option) => {
          return screeningQuestionValue?.split(',').indexOf(option?.value?.toString()) > -1;
        });
      }
      return options?.find((option) => {
        return option?.value?.toString() === screeningQuestionValue;
      });
    }

    return null;
  };

  const onScreeningSelect = async (id, typeId, value) => {
    let currentScreeningQuestions = candidatesFilter?.hasScreeningQuestions?.split('|') || [];

    if (value) {
      const selectedValue = typeId.toLowerCase() === ScreeningQuestionsType.MULTI_SELECT
        ? (value.map((item) => item.value)).join(',')
        : value;
      const sentValue = `${id}~${typeId}~${selectedValue}`;
      const isAlreadyInCurrentFilter = currentScreeningQuestions.find((screeningQuestion) =>
        screeningQuestion?.includes(`${id}~${typeId}`)
      );

      if (isAlreadyInCurrentFilter) {
        currentScreeningQuestions = currentScreeningQuestions.map((screeningQuestion) => {
          if (screeningQuestion?.includes(`${id}~${typeId}`)) {
            return sentValue;
          }

          return screeningQuestion;
        });
      } else {
        currentScreeningQuestions = [...currentScreeningQuestions, sentValue];
      }
    } else {
      currentScreeningQuestions = currentScreeningQuestions.filter(
        (screeningQuestion) => !screeningQuestion?.includes(`${id}~${typeId}`)
      );
    }

    if (currentScreeningQuestions?.length) {
      changeMeta('hasScreeningQuestions', currentScreeningQuestions.join('|'));
      return;
    }

    setMeta({
      ...meta,
      filters: [...meta?.filters?.filter((item) => item?.key !== 'hasScreeningQuestions')],
    });
  };

  return (
    <>
      <ButtonFilter isShown={isFilterShown} callback={setIsFilterShown} filters={get(meta, 'filters') || []} />
      {createPortal(
        <Filter show={isFilterShown}>
          <div className="flex justify-end">
            <button
              data-testid="filter-modal-close-button"
              type="button"
              onClick={() => {
                setIsFilterShown(false);
              }}
            >
              <Icon name="times" />
            </button>
          </div>
          <div className="candidates-filters">
            <SelectFilter
              searchable
              id="candidates-filter--position"
              label={t('general.position')}
              options={options?.positions || []}
              callback={onPositionSelect}
              keys={['id', 'name']}
              searchKey="name"
              api={getPositionLookupList}
              value={
                (position?.positionId || position?.id) && position?.name
                  ? { name: position.name, id: position?.positionId || position?.id }
                  : null
              }
              buttonProps={{
                'data-testid': 'filter-0-select',
              }}
            />
            <SelectFilter
              searchable
              id="candidates-filter--status"
              label={t('general.candidates')}
              options={statusOptions}
              callback={onStatusSelect}
              value={getFilterValue('rejected', statusOptions[0], (status) => {
                return statusOptions.find((item) => item?.rejectValue === status?.value);
              })}
              buttonProps={{
                'data-testid': 'filter-0-select',
              }}
            />
            <div className="filter mb-4">
              <p className="mb-2 text-sm font-bold">{t('general.rating')}</p>
              <StarRating clickable rate={getFilterValue('rating')?.value || 0} onClick={onRateSelect} />
            </div>
            <SelectFilter
              searchable
              id="candidates-filter--documents"
              label={t('candidates.table-header.documents')}
              options={documentsOptions}
              callback={async (selected) => {
                changeMeta('documents', selected?.value);
              }}
              value={getFilterValue('documents', documentsOptions[0], (doc) => {
                return documentsOptions.find((item) => item?.value === doc?.value);
              })}
              buttonProps={{
                'data-testid': 'filter-0-select',
              }}
            />
            <SelectFilter
              searchable
              id="candidates-filter--category"
              label={t('candidates.table-header.category')}
              options={categoryOptions}
              callback={async (selected) => {
                changeMeta('category', selected?.value);
              }}
              value={getFilterValue('category', categoryOptions[0], (category) => {
                return categoryOptions.find((item) => item?.value === category?.value);
              })}
              buttonProps={{
                'data-testid': 'filter-0-select',
              }}
            />
            <SelectFilter
              searchable
              multiple
              id="candidates-filter--tags"
              label={t('general.tags')}
              callback={onTagsSelect}
              // only name propery is returned by endpoint
              keys={['name', 'name']}
              onSelectListener={(selected, setDropdownState) => {
                setDropdownState(false);
              }}
              api={getTagsLookup}
              objectFilters={{
                page: {
                  number: 1,
                },
              }}
              value={selectedTags}
              searchKey="name"
              buttonProps={{
                'data-testid': 'filter-0-select',
              }}
            />
            {!!departmentsOptions?.length && !candidatesFilter?.positionId && (
              <SelectFilter
                id="position-filter--departments"
                label={t('general.departments')}
                value={
                  departmentValue
                    ? {
                        id: departmentValue?.value,
                        name: departmentValue?.label,
                      }
                    : null
                }
                api={browseDepartments}
                keys={['departmentId', 'name']}
                searchKey="name"
                callback={onDepartmentSelect}
                buttonProps={{
                  'data-testid': 'filter-2-select',
                }}
              />
            )}
            {!!screeningQuestions?.length && (
              <div className="mt-5">
                <h2>{t('general.screening-questions')}</h2>
                {screeningQuestions?.map((screeningQuestion, index) => {
                  const OPTIONS = createQuestions(screeningQuestion, t);
                  return (
                    <SelectFilter
                      searchable
                      multiple={!!screeningQuestion?.selections?.length}
                      id={`candidates-filter--question-${index}`}
                      key={`candidates-filter--question-${screeningQuestion?.id}`}
                      label={screeningQuestion.name}
                      value={getScreeningQuestionValue(screeningQuestion, OPTIONS)}
                      options={OPTIONS}
                      callback={(selected) => {
                        onScreeningSelect(
                          screeningQuestion?.id,
                          screeningQuestion?.type,
                          selected.length ? selected : (selected?.value || null)
                        );
                      }}
                    />
                  );
                })}
              </div>
            )}
          </div>
        </Filter>,
        document.body
      )}
    </>
  );
};

export default CandidatesFilter;
